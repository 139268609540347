import {WalletErrorHandler} from "@/errors/WalletErrors";

export default class OnChainRequestAbstract {
    web3 = {};

    constructor(web3) {
        this.web3 = web3;
    }

    handleTxError(error) {
        WalletErrorHandler.handleTxError(error);
    }
}
