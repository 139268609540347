var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "page-filters-section",
        { attrs: { "top-img": "/img/claim/coins-header.png" } },
        [
          _c("h1", { staticClass: "text-center" }, [
            _vm._v("Claim SCRL token"),
          ]),
          _c("span", { staticClass: "text-center" }, [
            _vm._v("Here you can claim tokens for Sage"),
          ]),
          _c("br"),
          _c("br"),
        ]
      ),
      _c(
        "v-col",
        { staticClass: "page-content" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "text-center hidden-sm-and-down",
                  attrs: { cols: "5" },
                },
                [
                  _c("img", {
                    staticClass: "hero-image ma-auto",
                    attrs: {
                      src: "/img/claim/coin-yellow-wizard.png",
                      alt: "Wizard claiming SCRL",
                    },
                  }),
                ]
              ),
              !_vm.isWalletConnected
                ? _c(
                    "v-col",
                    { attrs: { cols: "12", md: "7" } },
                    [_c("connect-wallet")],
                    1
                  )
                : _vm._e(),
              !_vm.vestingsLoaded && _vm.isWalletConnected
                ? _c(
                    "v-col",
                    { attrs: { cols: "12", md: "7" } },
                    [
                      _c("v-progress-circular", {
                        staticStyle: { width: "100% !important" },
                        attrs: { size: "300", indeterminate: "" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.vestingsLoaded && _vm.isWalletConnected
                ? _c(
                    "v-col",
                    { attrs: { cols: "12", md: "7" } },
                    [
                      _c(
                        "dialog-box",
                        {
                          attrs: {
                            title: "Claim details",
                            model: _vm.detailsDialog,
                          },
                          on: {
                            dialogClosed: function ($event) {
                              _vm.detailsDialog = false
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "text--primary mr-2" }, [
                            _vm._v("Contract address:"),
                          ]),
                          _c(
                            "a",
                            {
                              staticClass: "contract-address",
                              attrs: {
                                href:
                                  _vm.bscUrl +
                                  _vm.vestingContract.contractAddress,
                                target: "_blank",
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "icon",
                                attrs: { src: "/img/icons/crypto/bscscan.ico" },
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.vestingContract.contractAddress) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "vestings" },
                            _vm._l(_vm.vestings, function (item) {
                              return _c(
                                "v-card",
                                { key: item.id, staticClass: "vesting m-2" },
                                [
                                  _c("div", { staticClass: "vesting__row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "vesting__name" },
                                      [_vm._v("End date")]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "vesting__value caption" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("moment")(
                                                item.dateEnd,
                                                "Do MMMM YYYY"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "vesting__row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "vesting__name" },
                                      [_vm._v("Total Tokens")]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "vesting__value" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("localFormatNumber")(
                                                _vm._f("removeDecimals")(
                                                  item.totalTokens
                                                )
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "vesting__row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "vesting__name" },
                                      [_vm._v("Claimed Tokens")]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "vesting__value" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("localFormatNumber")(
                                                _vm._f("removeDecimals")(
                                                  item.claimedTokens
                                                )
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "vesting__row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "vesting__name" },
                                      [_vm._v("Left Tokens")]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "vesting__value" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("localFormatNumber")(
                                                _vm._f("removeDecimals")(
                                                  item.leftTokens
                                                )
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "vesting__row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "vesting__name" },
                                      [_vm._v("Tokens to claim")]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "vesting__value" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("localFormatNumber")(
                                                _vm._f("removeDecimals")(
                                                  item.tokensToClaim
                                                )
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              )
                            }),
                            1
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "mt-4 vesting-box" }, [
                        _c("div", { staticClass: "vesting" }, [
                          _c("div", { staticClass: "vesting__row" }, [
                            _c("div", { staticClass: "vesting__name" }, [
                              _vm._v("Total Tokens"),
                            ]),
                            _c("div", { staticClass: "vesting__value" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("localFormatNumber")(
                                      _vm._f("removeDecimals")(
                                        _vm.vestingsSum.totalTokens
                                      )
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "vesting__row" }, [
                            _c("div", { staticClass: "vesting__name" }, [
                              _vm._v("Claimed Tokens"),
                            ]),
                            _c("div", { staticClass: "vesting__value" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("localFormatNumber")(
                                      _vm._f("removeDecimals")(
                                        _vm.vestingsSum.claimedTokens
                                      )
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "vesting__row" }, [
                            _c("div", { staticClass: "vesting__name" }, [
                              _vm._v("Left Tokens"),
                            ]),
                            _c("div", { staticClass: "vesting__value" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("localFormatNumber")(
                                      _vm._f("removeDecimals")(
                                        _vm.vestingsSum.leftTokens
                                      )
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "vesting__row" }, [
                            _c("div", { staticClass: "vesting__name" }, [
                              _vm._v("Tokens to claim"),
                            ]),
                            _c("div", { staticClass: "vesting__value" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("localFormatNumber")(
                                      _vm._f("removeDecimals")(
                                        _vm.vestingsSum.tokensToClaim
                                      )
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "mt-8" },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  large: "",
                                  disabled:
                                    !_vm.vestingsLoaded ||
                                    _vm.vestingsSum.tokensToClaim <= 0,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.claimTokens()
                                  },
                                },
                              },
                              [_vm._v(" Claim ")]
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "pull-right",
                                attrs: {
                                  large: "",
                                  disabled: !_vm.vestingsLoaded,
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.detailsDialog = true
                                  },
                                },
                              },
                              [
                                _c("v-icon", [
                                  _vm._v("mdi-alert-circle-outline"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }