import BigNumber from "bignumber.js";
import {tokenContractDecimals} from "./tokenContract";
import OnChainRequestAbstract from "@/services/contracts/onChainRequestAbstract";
import {apiServiceVue} from "@/services/apiService.vue";

export default class AbstractContract extends OnChainRequestAbstract {
    static contractAddress = null;
    static contractAbi = null;

    account = null;
    _contract = null;
    tokenContract = null;
    DECIMALS = null;

    constructor(web3) {
        super(web3);
        this.account = web3.address;
        this.tokenContract = web3.tokenContract;
        this.DECIMALS = (new BigNumber(10)).pow(tokenContractDecimals);
    }

    /**
     *
     * @param {Number || String} value
     * @return {BigNumber}
     */
    _addDecimals(value) {
        return (new BigNumber(Number(value))).multipliedBy(this.DECIMALS);
    }

    async _ensureContractInitialized() {
        if (!this._contract) {
            this._contract = await new this.web3.eth.Contract(this.constructor.contractAbi, this.constructor.contractAddress);
        }
    }

    async getInitializedContract() {
        if (!this._contract) {
            this._contract = await new this.web3.eth.Contract(this.constructor.contractAbi, this.constructor.contractAddress);
        }
        return this._contract;
    }

    async _sendParams() {
        let returnObj = {from: this.account, gasPrice: null};

        return await apiServiceVue.get(process.env.VUE_APP_APP_URL + 'web3/gas-price')
            .then(res => {
                returnObj.gasPrice = res.data.gasPrice;
                return returnObj;
            })
            .catch(async (e) => {
                // Backup - use infura
                console.error('Backup infura', e);
                returnObj.gasPrice = await this.web3.eth.getGasPrice();
                return returnObj;
            });
    }
}
